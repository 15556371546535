import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect';
import Swiper from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

/** @SwiperConfig */
const swiperConfig = {
    direction: 'horizontal',
    loop: true,
    breakpoints: {
        640: {
            slidesPerView: 2.4,
        },
        768: {
            slidesPerView: 3.4,
        },
        1200: {
            slidesPerView: 3.4,
            spaceBetween: 32,
            slidesOffsetBefore: 32,
        }
    },
    slidesOffsetBefore: 16,
    spaceBetween: 16,
    slidesPerView: 1.8,
}

document.addEventListener("DOMContentLoaded", () => {
    const swiper = new Swiper('.swiper', {
        ...swiperConfig,
    });
    const sidelinjerSwiper = new Swiper('.swiper-sidelinjer', {...swiperConfig});
    const linjefagEntrySwiper = new Swiper('.swiper-linjefag-entry', {
        ...swiperConfig,
        breakpoints: {
            640: {
                slidesPerView: 2.4,
            },
            768: {
                slidesPerView: 3.4,
            },
            1200: {
                slidesPerView: 3.4,
                spaceBetween: 32,
                slidesOffsetBefore: 32,
            },
        },
        slidesPerView: 1.3,
        spaceBetween: 16,
        slidesOffsetBefore: 16
    });
    const quoteSwiper = new Swiper('.swiper-quote',
        {
            modules: [Pagination],
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
                bulletClass: '!w-3 !h-3 bg-transparent border-2 border-black !rounded-none ',
                currentClass: 'bg-black',
                bulletActiveClass: 'swiper-pagination-bullet-active !bg-black',
            }
        });

    window.swiper = swiper;
    window.sidelinjerSwiper = sidelinjerSwiper;
    window.linjefagEntrySwiper = linjefagEntrySwiper;
    window.quoteSwiper = quoteSwiper;

    Alpine.plugin(intersect)
    window.Alpine = Alpine
    Alpine.start()
});
